/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

// always include only once per project
@include mat.core();

// define 3 theme color
// mat-palette accepts $palette-name, main, lighter and darker variants
$my-theme-primary: mat.define-palette(mat.$gray-palette, 800, 500, 900);
$my-theme-accent: mat.define-palette(mat.$light-blue-palette);
$my-theme-warn: mat.define-palette(mat.$deep-orange-palette, A200);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$my-theme: mat.define-light-theme(
    $my-theme-primary,
    $my-theme-accent,
    $my-theme-warn
);

// use our theme with angular-material-theme mixin
@include mat.all-component-themes($my-theme);
